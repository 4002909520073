import {
  FunnelEngineConfig,
  FunnelPageActions,
  FunnelPageComponent,
  FunnelPageConfiguration,
  IFunnelEngineHookOptions,
} from './types';

type FunnelPageArgs<
  TData extends Record<string, unknown>,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string,
> = [
  component: FunnelPageComponent<TData, TContext, TErrors, TActions>,
  ...(TActions extends undefined
    ? [config?: Omit<FunnelPageConfiguration<TData, TContext, TActions, TErrors>, 'actions' | 'component'>]
    : [config: Omit<FunnelPageConfiguration<TData, TContext, TActions, TErrors>, 'component'>]),
];

export const funnelPage = <
  TData extends Record<string, unknown>,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string,
>(
  ...[component, config]: FunnelPageArgs<TData, TContext, TActions, TErrors>
): FunnelPageConfiguration<TData, TContext, TActions, TErrors> => {
  // @ts-expect-error actions
  return {
    component,
    actions: undefined,
    ...(config || {}),
  };
};

export const funnelConfiguration = <T extends FunnelEngineConfig>(config: IFunnelEngineHookOptions<T>) => config;
