import { Box } from '@mantine/core';
import { useTheme } from '@shared/theme';
import React, { CSSProperties, FunctionComponent } from 'react';

export const GradientBox: FunctionComponent<{ style: CSSProperties }> = ({ style }) => {
  const { theme: t } = useTheme();
  const theme = t.other.theme;

  return (
    <Box
      style={{
        background: `linear-gradient(to top, ${theme.screen.brand}, transparent)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(style || {}),
      }}
    />
  );
};
