import { Group, Image, ImageProps, Stack } from '@mantine/core';
import { useTheme } from '@shared/theme';
import { FunctionComponent, PropsWithChildren } from 'react';

export const EmblaPageLayout: FunctionComponent<
  PropsWithChildren<{ noPadding?: boolean; heroImageSrc?: ImageProps['src'] }>
> = ({ children, noPadding, heroImageSrc }) => {
  const { theme } = useTheme();

  return (
    <Stack px={0} h="100dvh">
      <Group gap={0} align="flex-start" wrap="nowrap" h={'100%'}>
        <Stack px="md" pt={'md'} flex={1} align="center" h={'100%'} gap={0}>
          <Group justify="center" w="100%">
            <Image src={theme.other.theme.assets.Logo} h={46} />
          </Group>
          <Stack flex={1} pt={noPadding ? undefined : 'xl'} mx="xl" w={heroImageSrc ? 572 : '100%'} h={'100%'}>
            {children}
          </Stack>
        </Stack>
        {heroImageSrc && (
          <Stack flex={1} h="100dvh" style={{ overflow: 'hidden' }}>
            <Image style={{ flex: 1 }} src={heroImageSrc} />
          </Stack>
        )}
      </Group>
    </Stack>
  );
};
