import { ReactComponent as IconSearch } from '@assets/icons/fi-rr-search.svg';
import { Group, Stack, Text, TextInput, UnstyledButton } from '@mantine/core';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import COUNTRY_PHONE_CODES_JSON from './countryPhoneCodes.json';

interface IProps {
  onSelect?: (value: string) => void;
  close: () => void;
}

export const EmblaPhoneInputCodePicker: FunctionComponent<IProps> = ({ onSelect, close }) => {
  const ref = useRef<HTMLInputElement>();
  const sortedCodes = COUNTRY_PHONE_CODES_JSON.sort((c1, c2) => {
    return Number(c1.dial_code.replace('+', '')) > Number(c2.dial_code.replace('+', '')) ? 1 : -1;
  });
  const [foundCodes, setFoundCodes] = useState(sortedCodes);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  const search = (value: string) => {
    if (!value) {
      setFoundCodes(sortedCodes);
    } else {
      setFoundCodes(
        sortedCodes.filter(
          (c) =>
            c.name.toLowerCase().includes(value.toLowerCase()) ||
            c.code.toLowerCase().includes(value.toLowerCase()) ||
            c.dial_code.replace('+', '').toLowerCase().includes(value.toLowerCase().replace('+', '')),
        ),
      );
    }
  };

  const notFound = foundCodes.length === 0;
  return (
    <Stack>
      <TextInput
        ref={ref}
        leftSection={<IconSearch width={20} />}
        placeholder={t('search')}
        onChange={(e) => {
          search(e.target.value);
        }}
      />
      {notFound && <Text>{t('notFound')}</Text>}
      {foundCodes.map((c) => {
        return (
          <UnstyledButton
            onClick={() => {
              onSelect(c.dial_code);
              close();
            }}
          >
            <Group justify="apart">
              <Text color="neutral.7" fw={400}>
                {c.name}
              </Text>
              <Text color="neutral.6" fw={400}>{`(${c.dial_code})`}</Text>
            </Group>
          </UnstyledButton>
        );
      })}
    </Stack>
  );
};
